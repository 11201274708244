/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Configure2faComponentViewModel } from '../models';
// @ts-ignore
import { ConfirmMagicLoginLinkModel } from '../models';
// @ts-ignore
import { CreateAccountModel } from '../models';
// @ts-ignore
import { ForgotPasswordViewModel } from '../models';
// @ts-ignore
import { InviteMemberModalViewModel } from '../models';
// @ts-ignore
import { LoginWithPasswordResult } from '../models';
// @ts-ignore
import { LoginWithPasswordViewModel } from '../models';
// @ts-ignore
import { MemberUserListItem } from '../models';
// @ts-ignore
import { ResetPasswordViewModel } from '../models';
// @ts-ignore
import { RoleModalViewModel } from '../models';
// @ts-ignore
import { SendMagicLoginLinkModel } from '../models';
// @ts-ignore
import { SwitchAccountListItem } from '../models';
// @ts-ignore
import { SwitchAccountLoginResult } from '../models';
// @ts-ignore
import { Verify2faCodeModel } from '../models';
// @ts-ignore
import { VerifyLogin2faCodeModel } from '../models';
// @ts-ignore
import { VerifyLogin2faResult } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountConfirmMagicLoginLinkPost: async (userId?: string, token?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ConfirmMagicLoginLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateAccountModel} [createAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateAccountPost: async (apiVersion?: string, createAccountModel?: CreateAccountModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/CreateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDeleteMemberDelete: async (emailAddress?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/DeleteMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisable2faPut: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/Disable2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPasswordPost: async (apiVersion?: string, forgotPasswordViewModel?: ForgotPasswordViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetChooseAccountListGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetChooseAccountList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetConfigure2faComponentViewModelGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetConfigure2faComponentViewModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetConfigure2faQrCodeGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetConfigure2faQrCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetMembersGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetSwitchAccountListGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetSwitchAccountList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {InviteMemberModalViewModel} [inviteMemberModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInviteMemberPost: async (apiVersion?: string, inviteMemberModalViewModel?: InviteMemberModalViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/InviteMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteMemberModalViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLoginToAccountGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/LoginToAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoginWithPasswordViewModel} [loginWithPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLoginWithPasswordPost: async (apiVersion?: string, loginWithPasswordViewModel?: LoginWithPasswordViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/LoginWithPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogoutGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPasswordPost: async (apiVersion?: string, resetPasswordViewModel?: ResetPasswordViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendMagicLoginLinkModel} [sendMagicLoginLinkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendMagicLoginLinkPost: async (apiVersion?: string, sendMagicLoginLinkModel?: SendMagicLoginLinkModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/SendMagicLoginLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMagicLoginLinkModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountTourStartedGet: async (page?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/TourStarted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RoleModalViewModel} [roleModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateRolePost: async (apiVersion?: string, roleModalViewModel?: RoleModalViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/UpdateRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleModalViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerify2faBackupCodeModelPost: async (apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/Verify2faBackupCodeModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyLogin2faCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Verify2faCodeModel} [verify2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyAndEnable2faPost: async (apiVersion?: string, verify2faCodeModel?: Verify2faCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/VerifyAndEnable2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verify2faCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyLogin2faCodeModelPost: async (apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/VerifyLogin2faCodeModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyLogin2faCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountConfirmMagicLoginLinkPost(userId?: string, token?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmMagicLoginLinkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountConfirmMagicLoginLinkPost(userId, token, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateAccountModel} [createAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountCreateAccountPost(apiVersion?: string, createAccountModel?: CreateAccountModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreateAccountPost(apiVersion, createAccountModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountDeleteMemberDelete(emailAddress?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountDeleteMemberDelete(emailAddress, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountDisable2faPut(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountDisable2faPut(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountForgotPasswordPost(apiVersion?: string, forgotPasswordViewModel?: ForgotPasswordViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountForgotPasswordPost(apiVersion, forgotPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetChooseAccountListGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwitchAccountListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetChooseAccountListGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetConfigure2faComponentViewModelGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Configure2faComponentViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetConfigure2faComponentViewModelGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetConfigure2faQrCodeGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetConfigure2faQrCodeGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetMembersGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberUserListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetMembersGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetSwitchAccountListGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwitchAccountListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetSwitchAccountListGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {InviteMemberModalViewModel} [inviteMemberModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountInviteMemberPost(apiVersion?: string, inviteMemberModalViewModel?: InviteMemberModalViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountInviteMemberPost(apiVersion, inviteMemberModalViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLoginToAccountGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwitchAccountLoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLoginToAccountGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoginWithPasswordViewModel} [loginWithPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLoginWithPasswordPost(apiVersion?: string, loginWithPasswordViewModel?: LoginWithPasswordViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginWithPasswordResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLoginWithPasswordPost(apiVersion, loginWithPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogoutGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogoutGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResetPasswordPost(apiVersion?: string, resetPasswordViewModel?: ResetPasswordViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResetPasswordPost(apiVersion, resetPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendMagicLoginLinkModel} [sendMagicLoginLinkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSendMagicLoginLinkPost(apiVersion?: string, sendMagicLoginLinkModel?: SendMagicLoginLinkModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSendMagicLoginLinkPost(apiVersion, sendMagicLoginLinkModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountTourStartedGet(page?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountTourStartedGet(page, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RoleModalViewModel} [roleModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateRolePost(apiVersion?: string, roleModalViewModel?: RoleModalViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateRolePost(apiVersion, roleModalViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerify2faBackupCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyLogin2faResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerify2faBackupCodeModelPost(apiVersion, verifyLogin2faCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Verify2faCodeModel} [verify2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyAndEnable2faPost(apiVersion?: string, verify2faCodeModel?: Verify2faCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyAndEnable2faPost(apiVersion, verify2faCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyLogin2faResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountConfirmMagicLoginLinkPost(userId?: string, token?: string, apiVersion?: string, options?: any): AxiosPromise<ConfirmMagicLoginLinkModel> {
            return localVarFp.accountConfirmMagicLoginLinkPost(userId, token, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateAccountModel} [createAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateAccountPost(apiVersion?: string, createAccountModel?: CreateAccountModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountCreateAccountPost(apiVersion, createAccountModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDeleteMemberDelete(emailAddress?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountDeleteMemberDelete(emailAddress, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisable2faPut(apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountDisable2faPut(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPasswordPost(apiVersion?: string, forgotPasswordViewModel?: ForgotPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountForgotPasswordPost(apiVersion, forgotPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetChooseAccountListGet(apiVersion?: string, options?: any): AxiosPromise<Array<SwitchAccountListItem>> {
            return localVarFp.accountGetChooseAccountListGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetConfigure2faComponentViewModelGet(apiVersion?: string, options?: any): AxiosPromise<Configure2faComponentViewModel> {
            return localVarFp.accountGetConfigure2faComponentViewModelGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetConfigure2faQrCodeGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountGetConfigure2faQrCodeGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetMembersGet(apiVersion?: string, options?: any): AxiosPromise<Array<MemberUserListItem>> {
            return localVarFp.accountGetMembersGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetSwitchAccountListGet(apiVersion?: string, options?: any): AxiosPromise<Array<SwitchAccountListItem>> {
            return localVarFp.accountGetSwitchAccountListGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {InviteMemberModalViewModel} [inviteMemberModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInviteMemberPost(apiVersion?: string, inviteMemberModalViewModel?: InviteMemberModalViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountInviteMemberPost(apiVersion, inviteMemberModalViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLoginToAccountGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<SwitchAccountLoginResult> {
            return localVarFp.accountLoginToAccountGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoginWithPasswordViewModel} [loginWithPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLoginWithPasswordPost(apiVersion?: string, loginWithPasswordViewModel?: LoginWithPasswordViewModel, options?: any): AxiosPromise<LoginWithPasswordResult> {
            return localVarFp.accountLoginWithPasswordPost(apiVersion, loginWithPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogoutGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountLogoutGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPasswordPost(apiVersion?: string, resetPasswordViewModel?: ResetPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountResetPasswordPost(apiVersion, resetPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendMagicLoginLinkModel} [sendMagicLoginLinkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendMagicLoginLinkPost(apiVersion?: string, sendMagicLoginLinkModel?: SendMagicLoginLinkModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountSendMagicLoginLinkPost(apiVersion, sendMagicLoginLinkModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountTourStartedGet(page?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountTourStartedGet(page, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RoleModalViewModel} [roleModalViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateRolePost(apiVersion?: string, roleModalViewModel?: RoleModalViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountUpdateRolePost(apiVersion, roleModalViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerify2faBackupCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: any): AxiosPromise<VerifyLogin2faResult> {
            return localVarFp.accountVerify2faBackupCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Verify2faCodeModel} [verify2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyAndEnable2faPost(apiVersion?: string, verify2faCodeModel?: Verify2faCodeModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountVerifyAndEnable2faPost(apiVersion, verify2faCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: any): AxiosPromise<VerifyLogin2faResult> {
            return localVarFp.accountVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {string} [userId] 
     * @param {string} [token] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountConfirmMagicLoginLinkPost(userId?: string, token?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountConfirmMagicLoginLinkPost(userId, token, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CreateAccountModel} [createAccountModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountCreateAccountPost(apiVersion?: string, createAccountModel?: CreateAccountModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountCreateAccountPost(apiVersion, createAccountModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [emailAddress] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountDeleteMemberDelete(emailAddress?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountDeleteMemberDelete(emailAddress, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountDisable2faPut(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountDisable2faPut(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountForgotPasswordPost(apiVersion?: string, forgotPasswordViewModel?: ForgotPasswordViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountForgotPasswordPost(apiVersion, forgotPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetChooseAccountListGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetChooseAccountListGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetConfigure2faComponentViewModelGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetConfigure2faComponentViewModelGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetConfigure2faQrCodeGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetConfigure2faQrCodeGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetMembersGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetMembersGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetSwitchAccountListGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetSwitchAccountListGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {InviteMemberModalViewModel} [inviteMemberModalViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountInviteMemberPost(apiVersion?: string, inviteMemberModalViewModel?: InviteMemberModalViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountInviteMemberPost(apiVersion, inviteMemberModalViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLoginToAccountGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountLoginToAccountGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {LoginWithPasswordViewModel} [loginWithPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLoginWithPasswordPost(apiVersion?: string, loginWithPasswordViewModel?: LoginWithPasswordViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountLoginWithPasswordPost(apiVersion, loginWithPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogoutGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountLogoutGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPasswordPost(apiVersion?: string, resetPasswordViewModel?: ResetPasswordViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountResetPasswordPost(apiVersion, resetPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SendMagicLoginLinkModel} [sendMagicLoginLinkModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountSendMagicLoginLinkPost(apiVersion?: string, sendMagicLoginLinkModel?: SendMagicLoginLinkModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountSendMagicLoginLinkPost(apiVersion, sendMagicLoginLinkModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [page] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountTourStartedGet(page?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountTourStartedGet(page, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {RoleModalViewModel} [roleModalViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateRolePost(apiVersion?: string, roleModalViewModel?: RoleModalViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdateRolePost(apiVersion, roleModalViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerify2faBackupCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerify2faBackupCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {Verify2faCodeModel} [verify2faCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyAndEnable2faPost(apiVersion?: string, verify2faCodeModel?: Verify2faCodeModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyAndEnable2faPost(apiVersion, verify2faCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(this.axios, this.basePath));
    }
}
