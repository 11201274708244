/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminAccountListItemModelListAndCountResult } from '../models';
// @ts-ignore
import { AdminAccountProfileViewModel } from '../models';
// @ts-ignore
import { AdminCancelAccountReasonViewModel } from '../models';
// @ts-ignore
import { AdminLoginResult } from '../models';
// @ts-ignore
import { AdminLoginViewModel } from '../models';
// @ts-ignore
import { AdminProfileViewModel } from '../models';
// @ts-ignore
import { AdminUpdateBillingInformationViewModel } from '../models';
// @ts-ignore
import { AdminUpdateBusinessInformationViewModel } from '../models';
// @ts-ignore
import { AdminUpdateContactInformationViewModel } from '../models';
// @ts-ignore
import { VerifyLogin2faCodeModel } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminLoginByAccountIdGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/AdminLoginByAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminLoginViewModel} [adminLoginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminLoginPost: async (apiVersion?: string, adminLoginViewModel?: AdminLoginViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/AdminLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLoginViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBillingPortalGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/BillingPortal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminCancelAccountReasonViewModel} [adminCancelAccountReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelAccountPost: async (apiVersion?: string, adminCancelAccountReasonViewModel?: AdminCancelAccountReasonViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CancelAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCancelAccountReasonViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAccountByIdGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GetAccountById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdminAccountsPageGet: async (page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GetAdminAccountsPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProfileGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBillingInformationViewModel} [adminUpdateBillingInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingInformationPost: async (apiVersion?: string, adminUpdateBillingInformationViewModel?: AdminUpdateBillingInformationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/UpdateBillingInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateBillingInformationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBusinessInformationViewModel} [adminUpdateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBusinessInformationPost: async (apiVersion?: string, adminUpdateBusinessInformationViewModel?: AdminUpdateBusinessInformationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/UpdateBusinessInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateBusinessInformationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateContactInformationViewModel} [adminUpdateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContactInformationPost: async (apiVersion?: string, adminUpdateContactInformationViewModel?: AdminUpdateContactInformationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/UpdateContactInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateContactInformationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVerifyLogin2faCodeModelPost: async (apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/VerifyLogin2faCodeModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyLogin2faCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminLoginByAccountIdGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminLoginByAccountIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminLoginViewModel} [adminLoginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminLoginPost(apiVersion?: string, adminLoginViewModel?: AdminLoginViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminLoginPost(apiVersion, adminLoginViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBillingPortalGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBillingPortalGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminCancelAccountReasonViewModel} [adminCancelAccountReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCancelAccountPost(apiVersion?: string, adminCancelAccountReasonViewModel?: AdminCancelAccountReasonViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCancelAccountPost(apiVersion, adminCancelAccountReasonViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAccountByIdGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountProfileViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAccountByIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAdminAccountsPageGet(page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountListItemModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAdminAccountsPageGet(page, skip, limit, sortField, isDesc, search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProfileGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProfileViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProfileGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBillingInformationViewModel} [adminUpdateBillingInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBillingInformationPost(apiVersion?: string, adminUpdateBillingInformationViewModel?: AdminUpdateBillingInformationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBillingInformationPost(apiVersion, adminUpdateBillingInformationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBusinessInformationViewModel} [adminUpdateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateBusinessInformationPost(apiVersion?: string, adminUpdateBusinessInformationViewModel?: AdminUpdateBusinessInformationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateBusinessInformationPost(apiVersion, adminUpdateBusinessInformationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateContactInformationViewModel} [adminUpdateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContactInformationPost(apiVersion?: string, adminUpdateContactInformationViewModel?: AdminUpdateContactInformationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContactInformationPost(apiVersion, adminUpdateContactInformationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminLoginByAccountIdGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminLoginByAccountIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminLoginViewModel} [adminLoginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminLoginPost(apiVersion?: string, adminLoginViewModel?: AdminLoginViewModel, options?: any): AxiosPromise<AdminLoginResult> {
            return localVarFp.adminAdminLoginPost(apiVersion, adminLoginViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBillingPortalGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminBillingPortalGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminCancelAccountReasonViewModel} [adminCancelAccountReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCancelAccountPost(apiVersion?: string, adminCancelAccountReasonViewModel?: AdminCancelAccountReasonViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminCancelAccountPost(apiVersion, adminCancelAccountReasonViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAccountByIdGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<AdminAccountProfileViewModel> {
            return localVarFp.adminGetAccountByIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdminAccountsPageGet(page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: any): AxiosPromise<AdminAccountListItemModelListAndCountResult> {
            return localVarFp.adminGetAdminAccountsPageGet(page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProfileGet(apiVersion?: string, options?: any): AxiosPromise<AdminProfileViewModel> {
            return localVarFp.adminProfileGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBillingInformationViewModel} [adminUpdateBillingInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBillingInformationPost(apiVersion?: string, adminUpdateBillingInformationViewModel?: AdminUpdateBillingInformationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUpdateBillingInformationPost(apiVersion, adminUpdateBillingInformationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateBusinessInformationViewModel} [adminUpdateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateBusinessInformationPost(apiVersion?: string, adminUpdateBusinessInformationViewModel?: AdminUpdateBusinessInformationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUpdateBusinessInformationPost(apiVersion, adminUpdateBusinessInformationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AdminUpdateContactInformationViewModel} [adminUpdateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContactInformationPost(apiVersion?: string, adminUpdateContactInformationViewModel?: AdminUpdateContactInformationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUpdateContactInformationPost(apiVersion, adminUpdateContactInformationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: any): AxiosPromise<AdminLoginResult> {
            return localVarFp.adminVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAdminLoginByAccountIdGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAdminLoginByAccountIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AdminLoginViewModel} [adminLoginViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAdminLoginPost(apiVersion?: string, adminLoginViewModel?: AdminLoginViewModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAdminLoginPost(apiVersion, adminLoginViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBillingPortalGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBillingPortalGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AdminCancelAccountReasonViewModel} [adminCancelAccountReasonViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCancelAccountPost(apiVersion?: string, adminCancelAccountReasonViewModel?: AdminCancelAccountReasonViewModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCancelAccountPost(apiVersion, adminCancelAccountReasonViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAccountByIdGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGetAccountByIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetAdminAccountsPageGet(page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGetAdminAccountsPageGet(page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProfileGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProfileGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AdminUpdateBillingInformationViewModel} [adminUpdateBillingInformationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateBillingInformationPost(apiVersion?: string, adminUpdateBillingInformationViewModel?: AdminUpdateBillingInformationViewModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUpdateBillingInformationPost(apiVersion, adminUpdateBillingInformationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AdminUpdateBusinessInformationViewModel} [adminUpdateBusinessInformationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateBusinessInformationPost(apiVersion?: string, adminUpdateBusinessInformationViewModel?: AdminUpdateBusinessInformationViewModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUpdateBusinessInformationPost(apiVersion, adminUpdateBusinessInformationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AdminUpdateContactInformationViewModel} [adminUpdateContactInformationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateContactInformationPost(apiVersion?: string, adminUpdateContactInformationViewModel?: AdminUpdateContactInformationViewModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUpdateContactInformationPost(apiVersion, adminUpdateContactInformationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {VerifyLogin2faCodeModel} [verifyLogin2faCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminVerifyLogin2faCodeModelPost(apiVersion?: string, verifyLogin2faCodeModel?: VerifyLogin2faCodeModel, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminVerifyLogin2faCodeModelPost(apiVersion, verifyLogin2faCodeModel, options).then((request) => request(this.axios, this.basePath));
    }
}
