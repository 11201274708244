/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKeyModel } from '../models';
// @ts-ignore
import { ConnectMarselloViewModel } from '../models';
// @ts-ignore
import { CreateApiKeyModel } from '../models';
// @ts-ignore
import { EditApiKeyModel } from '../models';
// @ts-ignore
import { IntegrationViewModel } from '../models';
// @ts-ignore
import { KlaviyoIntegrationViewModel } from '../models';
// @ts-ignore
import { LoopIntegrationViewModel } from '../models';
// @ts-ignore
import { SegmentIntegrationViewModel } from '../models';
// @ts-ignore
import { SlackChannelDto } from '../models';
// @ts-ignore
import { SlackIntegrationViewModel } from '../models';
// @ts-ignore
import { ToggleIntegrationViewModel } from '../models';
// @ts-ignore
import { ZendeskIntegrationViewModel } from '../models';
/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ConnectMarselloViewModel} [connectMarselloViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConnectMarselloPost: async (apiVersion?: string, connectMarselloViewModel?: ConnectMarselloViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ConnectMarsello`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectMarselloViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationDisableApiKeyDelete: async (token?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/DisableApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditApiKeyModel} [editApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationEditApiKeyDescriptionPost: async (apiVersion?: string, editApiKeyModel?: EditApiKeyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/EditApiKeyDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editApiKeyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateApiKeyModel} [createApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGenerateNewApiKeyPost: async (apiVersion?: string, createApiKeyModel?: CreateApiKeyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/GenerateNewApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createApiKeyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetApiKeysGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/GetApiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetByAccountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/GetByAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetLoopWebhookUrlGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/GetLoopWebhookUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetSlackChannelsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/GetSlackChannels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ToggleIntegrationViewModel} [toggleIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleIntegrationPost: async (apiVersion?: string, toggleIntegrationViewModel?: ToggleIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KlaviyoIntegrationViewModel} [klaviyoIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleKlaviyoIntegrationPost: async (apiVersion?: string, klaviyoIntegrationViewModel?: KlaviyoIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleKlaviyoIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(klaviyoIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoopIntegrationViewModel} [loopIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleLoopIntegrationPost: async (apiVersion?: string, loopIntegrationViewModel?: LoopIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleLoopIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loopIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SegmentIntegrationViewModel} [segmentIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleSegmentIntegrationPost: async (apiVersion?: string, segmentIntegrationViewModel?: SegmentIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleSegmentIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(segmentIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SlackIntegrationViewModel} [slackIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleSlackIntegrationPost: async (apiVersion?: string, slackIntegrationViewModel?: SlackIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleSlackIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ZendeskIntegrationViewModel} [zendeskIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleZendeskIntegrationPost: async (apiVersion?: string, zendeskIntegrationViewModel?: ZendeskIntegrationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Integration/ToggleZendeskIntegration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zendeskIntegrationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ConnectMarselloViewModel} [connectMarselloViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationConnectMarselloPost(apiVersion?: string, connectMarselloViewModel?: ConnectMarselloViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationConnectMarselloPost(apiVersion, connectMarselloViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationDisableApiKeyDelete(token?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationDisableApiKeyDelete(token, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditApiKeyModel} [editApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationEditApiKeyDescriptionPost(apiVersion?: string, editApiKeyModel?: EditApiKeyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationEditApiKeyDescriptionPost(apiVersion, editApiKeyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateApiKeyModel} [createApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationGenerateNewApiKeyPost(apiVersion?: string, createApiKeyModel?: CreateApiKeyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationGenerateNewApiKeyPost(apiVersion, createApiKeyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationGetApiKeysGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKeyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationGetApiKeysGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationGetByAccountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationGetByAccountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationGetLoopWebhookUrlGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationGetLoopWebhookUrlGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationGetSlackChannelsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlackChannelDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationGetSlackChannelsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ToggleIntegrationViewModel} [toggleIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleIntegrationPost(apiVersion?: string, toggleIntegrationViewModel?: ToggleIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleIntegrationPost(apiVersion, toggleIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KlaviyoIntegrationViewModel} [klaviyoIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleKlaviyoIntegrationPost(apiVersion?: string, klaviyoIntegrationViewModel?: KlaviyoIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleKlaviyoIntegrationPost(apiVersion, klaviyoIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoopIntegrationViewModel} [loopIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleLoopIntegrationPost(apiVersion?: string, loopIntegrationViewModel?: LoopIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleLoopIntegrationPost(apiVersion, loopIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SegmentIntegrationViewModel} [segmentIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleSegmentIntegrationPost(apiVersion?: string, segmentIntegrationViewModel?: SegmentIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleSegmentIntegrationPost(apiVersion, segmentIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SlackIntegrationViewModel} [slackIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleSlackIntegrationPost(apiVersion?: string, slackIntegrationViewModel?: SlackIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleSlackIntegrationPost(apiVersion, slackIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ZendeskIntegrationViewModel} [zendeskIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationToggleZendeskIntegrationPost(apiVersion?: string, zendeskIntegrationViewModel?: ZendeskIntegrationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationToggleZendeskIntegrationPost(apiVersion, zendeskIntegrationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ConnectMarselloViewModel} [connectMarselloViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConnectMarselloPost(apiVersion?: string, connectMarselloViewModel?: ConnectMarselloViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.integrationConnectMarselloPost(apiVersion, connectMarselloViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationDisableApiKeyDelete(token?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationDisableApiKeyDelete(token, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditApiKeyModel} [editApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationEditApiKeyDescriptionPost(apiVersion?: string, editApiKeyModel?: EditApiKeyModel, options?: any): AxiosPromise<void> {
            return localVarFp.integrationEditApiKeyDescriptionPost(apiVersion, editApiKeyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateApiKeyModel} [createApiKeyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGenerateNewApiKeyPost(apiVersion?: string, createApiKeyModel?: CreateApiKeyModel, options?: any): AxiosPromise<void> {
            return localVarFp.integrationGenerateNewApiKeyPost(apiVersion, createApiKeyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetApiKeysGet(apiVersion?: string, options?: any): AxiosPromise<Array<ApiKeyModel>> {
            return localVarFp.integrationGetApiKeysGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetByAccountGet(apiVersion?: string, options?: any): AxiosPromise<IntegrationViewModel> {
            return localVarFp.integrationGetByAccountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetLoopWebhookUrlGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.integrationGetLoopWebhookUrlGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationGetSlackChannelsGet(apiVersion?: string, options?: any): AxiosPromise<Array<SlackChannelDto>> {
            return localVarFp.integrationGetSlackChannelsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ToggleIntegrationViewModel} [toggleIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleIntegrationPost(apiVersion?: string, toggleIntegrationViewModel?: ToggleIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleIntegrationPost(apiVersion, toggleIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KlaviyoIntegrationViewModel} [klaviyoIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleKlaviyoIntegrationPost(apiVersion?: string, klaviyoIntegrationViewModel?: KlaviyoIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleKlaviyoIntegrationPost(apiVersion, klaviyoIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {LoopIntegrationViewModel} [loopIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleLoopIntegrationPost(apiVersion?: string, loopIntegrationViewModel?: LoopIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleLoopIntegrationPost(apiVersion, loopIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SegmentIntegrationViewModel} [segmentIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleSegmentIntegrationPost(apiVersion?: string, segmentIntegrationViewModel?: SegmentIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleSegmentIntegrationPost(apiVersion, segmentIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SlackIntegrationViewModel} [slackIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleSlackIntegrationPost(apiVersion?: string, slackIntegrationViewModel?: SlackIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleSlackIntegrationPost(apiVersion, slackIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ZendeskIntegrationViewModel} [zendeskIntegrationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationToggleZendeskIntegrationPost(apiVersion?: string, zendeskIntegrationViewModel?: ZendeskIntegrationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.integrationToggleZendeskIntegrationPost(apiVersion, zendeskIntegrationViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ConnectMarselloViewModel} [connectMarselloViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationConnectMarselloPost(apiVersion?: string, connectMarselloViewModel?: ConnectMarselloViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationConnectMarselloPost(apiVersion, connectMarselloViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationDisableApiKeyDelete(token?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationDisableApiKeyDelete(token, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {EditApiKeyModel} [editApiKeyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationEditApiKeyDescriptionPost(apiVersion?: string, editApiKeyModel?: EditApiKeyModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationEditApiKeyDescriptionPost(apiVersion, editApiKeyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CreateApiKeyModel} [createApiKeyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationGenerateNewApiKeyPost(apiVersion?: string, createApiKeyModel?: CreateApiKeyModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationGenerateNewApiKeyPost(apiVersion, createApiKeyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationGetApiKeysGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationGetApiKeysGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationGetByAccountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationGetByAccountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationGetLoopWebhookUrlGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationGetLoopWebhookUrlGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationGetSlackChannelsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationGetSlackChannelsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ToggleIntegrationViewModel} [toggleIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleIntegrationPost(apiVersion?: string, toggleIntegrationViewModel?: ToggleIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleIntegrationPost(apiVersion, toggleIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {KlaviyoIntegrationViewModel} [klaviyoIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleKlaviyoIntegrationPost(apiVersion?: string, klaviyoIntegrationViewModel?: KlaviyoIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleKlaviyoIntegrationPost(apiVersion, klaviyoIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {LoopIntegrationViewModel} [loopIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleLoopIntegrationPost(apiVersion?: string, loopIntegrationViewModel?: LoopIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleLoopIntegrationPost(apiVersion, loopIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SegmentIntegrationViewModel} [segmentIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleSegmentIntegrationPost(apiVersion?: string, segmentIntegrationViewModel?: SegmentIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleSegmentIntegrationPost(apiVersion, segmentIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SlackIntegrationViewModel} [slackIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleSlackIntegrationPost(apiVersion?: string, slackIntegrationViewModel?: SlackIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleSlackIntegrationPost(apiVersion, slackIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ZendeskIntegrationViewModel} [zendeskIntegrationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public integrationToggleZendeskIntegrationPost(apiVersion?: string, zendeskIntegrationViewModel?: ZendeskIntegrationViewModel, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).integrationToggleZendeskIntegrationPost(apiVersion, zendeskIntegrationViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
