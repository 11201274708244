/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const GiftCardType = {
    GiftCard: 'GiftCard',
    DiscountCode: 'DiscountCode',
    WrappedPaymentType: 'WrappedPaymentType',
    SmartCoupon: 'SmartCoupon'
} as const;

export type GiftCardType = typeof GiftCardType[keyof typeof GiftCardType];



