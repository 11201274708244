/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ReturnIdResult } from '../models';
// @ts-ignore
import { ShopifyOrderRefundedModel } from '../models';
// @ts-ignore
import { ShopifyRefundViewModel } from '../models';
// @ts-ignore
import { VendRefundPostModel } from '../models';
/**
 * RefundApi - axios parameter creator
 * @export
 */
export const RefundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifyRefundViewModel} [shopifyRefundViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundCreateShopifyRefundPost: async (apiVersion?: string, shopifyRefundViewModel?: ShopifyRefundViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Refund/CreateShopifyRefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopifyRefundViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VendRefundPostModel} [vendRefundPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundCreateVendRefundAsGiftCardPost: async (apiVersion?: string, vendRefundPostModel?: VendRefundPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Refund/CreateVendRefundAsGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendRefundPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [vendCustomerId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundGetVendCustomerEmailAddressGet: async (accountPlatformId?: string, vendCustomerId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Refund/GetVendCustomerEmailAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountPlatformId !== undefined) {
                localVarQueryParameter['accountPlatformId'] = accountPlatformId;
            }

            if (vendCustomerId !== undefined) {
                localVarQueryParameter['vendCustomerId'] = vendCustomerId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [shop] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundShopifyRefundGet: async (id?: string, shop?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Refund/ShopifyRefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefundApi - functional programming interface
 * @export
 */
export const RefundApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefundApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifyRefundViewModel} [shopifyRefundViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundCreateShopifyRefundPost(apiVersion?: string, shopifyRefundViewModel?: ShopifyRefundViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyOrderRefundedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundCreateShopifyRefundPost(apiVersion, shopifyRefundViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VendRefundPostModel} [vendRefundPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundCreateVendRefundAsGiftCardPost(apiVersion?: string, vendRefundPostModel?: VendRefundPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnIdResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundCreateVendRefundAsGiftCardPost(apiVersion, vendRefundPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [vendCustomerId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundGetVendCustomerEmailAddressGet(accountPlatformId?: string, vendCustomerId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundGetVendCustomerEmailAddressGet(accountPlatformId, vendCustomerId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [shop] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundShopifyRefundGet(id?: string, shop?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopifyRefundViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundShopifyRefundGet(id, shop, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefundApi - factory interface
 * @export
 */
export const RefundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefundApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifyRefundViewModel} [shopifyRefundViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundCreateShopifyRefundPost(apiVersion?: string, shopifyRefundViewModel?: ShopifyRefundViewModel, options?: any): AxiosPromise<ShopifyOrderRefundedModel> {
            return localVarFp.refundCreateShopifyRefundPost(apiVersion, shopifyRefundViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VendRefundPostModel} [vendRefundPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundCreateVendRefundAsGiftCardPost(apiVersion?: string, vendRefundPostModel?: VendRefundPostModel, options?: any): AxiosPromise<ReturnIdResult> {
            return localVarFp.refundCreateVendRefundAsGiftCardPost(apiVersion, vendRefundPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [vendCustomerId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundGetVendCustomerEmailAddressGet(accountPlatformId?: string, vendCustomerId?: string, apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.refundGetVendCustomerEmailAddressGet(accountPlatformId, vendCustomerId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [shop] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundShopifyRefundGet(id?: string, shop?: string, apiVersion?: string, options?: any): AxiosPromise<ShopifyRefundViewModel> {
            return localVarFp.refundShopifyRefundGet(id, shop, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefundApi - object-oriented interface
 * @export
 * @class RefundApi
 * @extends {BaseAPI}
 */
export class RefundApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ShopifyRefundViewModel} [shopifyRefundViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundApi
     */
    public refundCreateShopifyRefundPost(apiVersion?: string, shopifyRefundViewModel?: ShopifyRefundViewModel, options?: AxiosRequestConfig) {
        return RefundApiFp(this.configuration).refundCreateShopifyRefundPost(apiVersion, shopifyRefundViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {VendRefundPostModel} [vendRefundPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundApi
     */
    public refundCreateVendRefundAsGiftCardPost(apiVersion?: string, vendRefundPostModel?: VendRefundPostModel, options?: AxiosRequestConfig) {
        return RefundApiFp(this.configuration).refundCreateVendRefundAsGiftCardPost(apiVersion, vendRefundPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountPlatformId] 
     * @param {string} [vendCustomerId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundApi
     */
    public refundGetVendCustomerEmailAddressGet(accountPlatformId?: string, vendCustomerId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return RefundApiFp(this.configuration).refundGetVendCustomerEmailAddressGet(accountPlatformId, vendCustomerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [shop] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundApi
     */
    public refundShopifyRefundGet(id?: string, shop?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return RefundApiFp(this.configuration).refundShopifyRefundGet(id, shop, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
